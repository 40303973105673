import React, { Suspense } from 'react';
import Layout from './layout';
import { useAuth } from './context/auth';
import Loader from './element/general/Loader';

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
// Fontawesome
import '@fortawesome/fontawesome-free/css/all.css';
// Default CSS
import '../assets/css/App.css';
import Router from './module/common/Router';
import { BrowserRouter } from 'react-router-dom';

const Login = React.lazy(() => import('./page/Login'));

function App() {
  const auth = useAuth();

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
        <Layout>
          {
            auth.verify()
            ?
            <Router />
            :
            <Login />
          }
        </Layout>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
