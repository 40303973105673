import React, { Suspense, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useAPI } from '../context/api';
import { useAuth } from "../context/auth";
import Loader from "../element/general/Loader";

function useParams(path) {
  const { pathname } = useLocation()
  const match = matchPath({ path }, pathname)
  return match?.params || {}
}

function useParamsInt(path, key) {
  let params = useParams(path);

  return params[key] ? parseInt(params[key]) : null;
}

export default function Content ({children}) {
  const api = useAPI();
  const auth = useAuth();

  const eventId = useParamsInt('/:eventId', "eventId");
  
  const [activeEvent, setActiveEvent] = useState(null);

  useEffect(() => {
    api.event.getActiveEvents().then(eventsData => {
      if (eventsData.length > 0) {
        let tmpActiveEvent;
        
        if (typeof eventId !== "undefined") {
          tmpActiveEvent = eventsData.find((eventData) => {
            return eventData.id === eventId;
          });
        }

        setActiveEvent(tmpActiveEvent??eventsData[0]);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  return (
    <div id="content" style={{
      backgroundColor: activeEvent?activeEvent.design_background_color:"#ffffff",
      backgroundImage: !auth.verify()&&activeEvent?`url('${activeEvent.design_image}')`:"none"
    }}>
      <Suspense fallback={<Loader />}>
        {children}
      </Suspense>
    </div>
  );
}