import axios from 'axios';

import { API_URL } from '../../../config/default';
import { useAuth } from '../auth';
import { useCache } from '../cache';
import { useError } from "../error";


export function Organizer () {
  const cache = useCache();
  const errors = useError();
  const { token } = useAuth();

  const getOrganizers = async (event_id) => {
    errors.clearErrors();

    if (!cache.has(`event${event_id}_organizers`)) {
      cache.setData(
        `event${event_id}_organizers`,
        axios.get(`${API_URL}organizer`, {
          params: {
            token: token,
            filter: {
              event_id: event_id
            },
            page: 0
          }
        }).then(response => {
          return response.data;
        }).catch(error => {
          errors.updateErrors(error.response.data);

          return [];
        })
      );
    }

    return cache.getData(`event${event_id}_organizers`);
  }

  return {
    getOrganizers
  }
}