import React, { useContext, createContext } from "react";
import { Activity } from "./activity";
import { Event } from "./event";
import { EventCategory } from "./eventcategory";
import { Organizer } from "./organizer";
import { Team } from "./team";
import { User } from "./user";

const apiContext = createContext();

export function ProvideAPI({ children }) {
  const auth = useProvideAPI();
  return <apiContext.Provider value={auth}>{children}</apiContext.Provider>;
}

export const useAPI = () => {
  return useContext(apiContext);
};

function useProvideAPI() {
  const activity = Activity();
  const event = Event();
  const eventCategory = EventCategory();
  const organizer = Organizer();
  const team = Team();
  const user = User();

  return {
    activity,
    event,
    eventCategory,
    organizer,
    team,
    user
  }
}