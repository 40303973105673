import axios from 'axios';

import { API_URL } from '../../../config/default';
import { useAuth } from '../auth';
import { useCache } from '../cache';
import { useError } from "../error";

export function Team () {
  const errors = useError();
  const { token } = useAuth();
  const cache = useCache();

  const create = async (teamName, eventId) => {
    return axios.post(
      `${API_URL}team`,
      {
        token: token,
        name: teamName,
        event_id: eventId
      }
    ).then(response => {
      cache.remove(`join_data_${eventId}`);
      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
      return {};
    });
  }

  const join = async (invitationHash, eventId) => {
    return axios.post(
      `${API_URL}team/join`,
      {
        token: token,
        invitation_hash: invitationHash,
        event_id: eventId
      }
    ).then(response => {
      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
      return {};
    });
  }

  const info = async (teamId) => {
    if (!cache.has(`team_info_${teamId}`)) {
      cache.setData(
        `team_info_${teamId}`,
        axios.get(
          `${API_URL}team/${teamId}/info`,
          {
            params: {
              token: token
            }
          }
        ).then(response => {
          return response.data;
        }).catch(error => {
          errors.updateErrors(error.response.data);
          return {};
        }),
        'short'
      );
    }

    return cache.getData(`team_info_${teamId}`);
  }

  return {
    create,
    join,
    info
  }
}