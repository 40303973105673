import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ProvideError } from './components/context/error';
import { ProvideAPI } from './components/context/api';
import { ProvideAuth } from './components/context/auth';
import App from './components/App';
import Loader from './components/element/general/Loader';
import { ProvideCache } from './components/context/cache';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ProvideError>
      <ProvideCache>
        <ProvideAuth>
          <ProvideAPI>
            <Suspense fallback={<Loader />}>
              <App />
            </Suspense>
          </ProvideAPI>
        </ProvideAuth>
      </ProvideCache>
    </ProvideError>
  // </React.StrictMode>
);