import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";

export default function UserInfo ({ userImage, userName, userDisplayName }) {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <div className="ms-auto dropdown">
      <div id="header-user-info" className="ms-0" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <img src={userImage} alt={userName} title={userName} className="user-image" />
        <p className="user-name">
          <span className="main-name">{userName}</span><br />
          {userDisplayName}
        </p>
      </div>
      <ul className="dropdown-menu">
        <li><button className="dropdown-item"onClick={() => {auth.signout();navigate("/")}}>Kijelentkezés</button></li>
      </ul>
    </div>
    
  );
}