import axios from 'axios';

import { API_URL } from '../../../config/default';
import { useAuth } from '../auth';
import { useCache } from '../cache';
import { useError } from "../error";

export function Activity () {
  const errors = useError();
  const { token } = useAuth();
  const cache = useCache();

  const getActivities = async (eventId, userId) => {
    if (!cache.has(`event_${eventId}_activities`)) {
      let params = {
        token: token,
        event_id: eventId,
        page: 0
      };

      if (typeof userId !== "undefined") params.user_id = userId

      cache.setData(
        `event_${eventId}_activities`,
        axios.get(API_URL + 'activity', {
          params: params
        }).then(response => {
          return response.data;
        }).catch(error => {
          errors.updateErrors(error.response.data);
          return [];
        })
      );
    }

    return cache.getData(`event_${eventId}_activities`);
  }

  const add = async (eventId, activityData, onSuccess) => {
    errors.clearErrors();

    return axios.post(
      `${API_URL}activity`,
      {
        token: token,
        event_id: eventId,
        ...activityData
      }
    ).then(response => {
      errors.updateErrors([{
        level: 'SUCCESS',
        message: 'Sikeres aktivitás felvétel!'
      }]);

      cache.remove(`event_${eventId}_activities`);
      cache.removeByPrefix('team_info_');

      if (typeof onSuccess !== "undefined") onSuccess(response.data);

      return response.data;
    })
    .catch(error => {
      if (error.response)
        errors.updateErrors(error.response.data);
      else
        console.log(error);

      return {};
    });
  }

  return {
    getActivities,
    add
  }
}