import {
  Step,
  Distance
} from "../assets/images/icons";

export const API_URL = 'https://api.magadertsportolj.hu/';
//export const API_URL = 'http://localhost:8000/';

export const dateConfig = {
  day: "2-digit",
  year: "numeric",
  month: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
};

export const joinTypes = [
  {
    name: "Egyéni",
    icon: <i className="fa-solid fa-person"></i>,
    value: "SINGLE"
  },
  {
    name: "Csapat",
    icon: <i className="fa-solid fa-people-group"></i>,
    value: "TEAM"
  }
];

export const targetTypes = [
  {
    name: "Távolság",
    value: "DISTANCE",
    unit: "méter",
    icon: Distance
  },
  {
    name: "Lépésszám",
    value: "STEPS",
    unit: "lépés",
    icon: Step
  }
];

export const months = [
  "január",
  "február",
  "március",
  "április",
  "május",
  "június",
  "július",
  "augusztus",
  "szeptember",
  "október",
  "november",
  "december"
];

export const displayDateRange = (from, to) => {
  return `${from.getFullYear()} ${months[from.getMonth()]} ${from.getDate()}. - ${from.getYear() === to.getYear() ? '' : to.getFullYear() + ' '}${months[to.getMonth()]} ${to.getDate()}.`;
}