import axios from 'axios';

import { API_URL } from '../../../config/default';
import { useAuth } from '../auth';
import { useCache } from '../cache';
import { useError } from "../error";

export function User () {
  const errors = useError();
  const { token } = useAuth();
  const cache = useCache();

  const getUsers = async () => {
    if (!cache.has("users")) {
      cache.setData(
        "users",
        axios.get(API_URL + 'event', {
          params: {
            token: token,
            filter: {
              status: "ACTIVE"
            },
            order_by: "DATE_START",
            order: "ASC"
          }
        }).then(response => {
          return response.data;
        }).catch(error => {
          errors.updateErrors(error.response.data);
          return {};
        })
      );
    }

    return cache.getData("users");
  }

  const get = async (id) => {
    if (!cache.has(`user_${id}`)) {
      cache.setData(
        `user_${id}`,
        axios.get(`${API_URL}user/${id}`, {
          params: {
            token: token
          }
        }).then(response => {
          return response.data;
        }).catch(error => {
          errors.updateErrors(error.response.data);
          return {};
        })
      );
    }
    return cache.getData(`user_${id}`);
  }

  const getSelf = () => {
    if (!cache.has(`user_self`)) {
      cache.setData(
        `user_self`,
        axios.get(`${API_URL}user/self?token=${token}`).then(response => {
          return response.data;
        }).catch(error => {
          errors.updateErrors(error.response.data);
          return {};
        })
      );
    }

    return cache.getData(`user_self`);
  }
  const save = async (data, onSuccess) => {
    // Clear User Self Cache
    cache.remove("user_self");

    let url = `${API_URL}user/self`;
    let postData = {
      token: token,
      ...data
    }

    if (token === null ) {
      url = `${API_URL}user`;
      postData = data;
    }

    return axios.post(
      url,
      postData
    ).then(response => {
      errors.updateErrors([{
        level: 'SUCCESS',
        message: `Sikeres ${token?'mentés':'regisztráció'}!`
      }]);

      if (typeof onSuccess !== "undefined") onSuccess(response.data);

      return response.data;
    })
    .catch(error => {
      if (error.response)
        errors.updateErrors(error.response.data);
      else
        console.log(error);

      return {};
    });
  }
  
  // User login
  const login = async (email, password) => {
    
    return axios.post(
      `${API_URL}user/login`,
      {
        type: 'EMAIL',
        email: email,
        password: password
      }
    ).then(response => {
      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
      return {};
    })
  }

  // User logout
  const logout = async () => {
    // Clear Cache
    cache.clear();
  }

  return {
    getUsers,
    get,
    getSelf,
    save,
    login,
    logout
  }
}