import React, { useState, useContext, createContext, useEffect } from "react";
import axios from 'axios';
import useToken from "./useToken";
import { API_URL } from '../../../config/default';
import { useCache } from "../cache";
import { useError } from "../error";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const { token, setToken } = useToken();
  const cache = useCache();
  const errors = useError()

  
  useEffect(function(){
    if (!user && token) {
      loadUserData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  
  const verify = () => {
    // TODO: Verify token through API

    return token !== null;
  }

  const signin = async (email, password) => {
    // API authentication
    return axios.post(
      `${API_URL}user/login`,
      {
        type: 'EMAIL',
        email: email,
        password: password
      }
    ).then(response => {
      
      if (response.data.token) {
        setToken(response.data.token);
      }

      return response.data;
    }).catch(error => {
      errors.updateErrors(error.response.data);
      return false;
    });
  };
  
  const signout = () => {
    // Send Token Invalidate Request to API
    
    setToken(null);
    setUser(null);

    cache.clear();

    return true;
  };

  const register = async (data) => {
    // API authentication
    return axios.post(
      `${API_URL}user`,
      data
    ).then(response => {
      errors.updateErrors([{
        level: 'SUCCESS',
        message: 'Sikeres regisztráció!'
      }]);

      if (response.data.token) {
        setToken(response.data.token);
      }

      return response.data;
    })
    .catch(error => {
      if (error.response)
        errors.updateErrors(error.response.data);
      else
        console.log(error);

      return false;
    });
  };

  const sendPasswordResetEmail = (email) => {
    // TODO: Send API request password reset
    
    return axios.post(
      `${API_URL}user/forgotten-password`,
      {
        email: email
      }
    ).then(response => {
      errors.updateErrors([{
        level: 'SUCCESS',
        message: 'Visszaállító kód sikeresen kiküldve!'
      }]);

      return true;
    })
    .catch(error => {
      if (error.response)
        errors.updateErrors(error.response.data);
      else
        console.log(error);

      return false;
    });
  };

  const confirmPasswordReset = (email, code, password, passwordRepeat) => {
    // TODO: Send API confirm password reset

    return axios.post(
      `${API_URL}user/password-recovery`,
      {
        email: email,
        code: code,
        password: password,
        password_repeat: passwordRepeat
      }
    ).then(response => {
      errors.updateErrors([{
        level: 'SUCCESS',
        message: 'Sikeres jelszó visszaállítás!'
      }]);

      return true;
    })
    .catch(error => {
      if (error.response)
        errors.updateErrors(error.response.data);
      else
        console.log(error);

      return false;
    });
  };

  const loadUserData = (onComplete) => {
    if(token) {
      axios.get(API_URL + "user/self?token="+token).then(function (userResponse){
        if (typeof onComplete != "undefined") onComplete();
        
        if(userResponse.status === 200) {
          setUser(userResponse.data);
          return true;
        } else {
          // Error in user data request
          // TODO: Error Handling
          
          return false;
        }
      }).catch(function (error){
        if (typeof onComplete != "undefined") onComplete();
        console.log(error);
        setToken(null);
        return false;
      });
    }
  }

  // Return the user object and auth methods
  return {
    user,
    token,
    verify,
    signin,
    signout,
    register,
    sendPasswordResetEmail,
    confirmPasswordReset,
    loadUserData
  };
}