import axios from 'axios';

import { API_URL } from '../../../config/default';
import { useAuth } from '../auth';
import { useCache } from '../cache';
import { useError } from "../error";

export function Event () {
  const errors = useError();
  const { token } = useAuth();
  const cache = useCache();

  const getActiveEvents = async () => {
    if (!cache.has("active_events")) {
      cache.setData(
        "active_events",
        axios.get(API_URL + 'event', {
          params: {
            token: token,
            filter: {
              status: "ACTIVE"
            },
            order_by: "DATE_START",
            order: "ASC"
          }
        }).then(response => {
          return response.data;
        }).catch(error => {
          errors.updateErrors(error.response.data);
          return {};
        })
      );
    }

    return cache.getData("active_events");
  }

  const getEvent = async (id) => {
    if (!cache.has(`event_${id}`)) {
      cache.setData(
        `event_${id}`,
        axios.get(`${API_URL}event/${id}`, {
          params: {
            token: token
          }
        }).then(response => {
          return response.data;
        }).catch(error => {
          errors.updateErrors(error.response.data);
          return {};
        })
      );
    }
    return cache.getData(`event_${id}`);
  }

  const join = async (id, type, onSuccess ) => {
    errors.clearErrors();

    return axios.post(
      `${API_URL}event/${id}/join`,
      {
        token: token,
        join_type: type
      }
    ).then(response => {
      errors.updateErrors([{
        level: 'SUCCESS',
        message: 'Sikeres csatlakozás!'
      }]);
      cache.removeByPrefix('join_data_');

      if (typeof onSuccess !== "undefined") onSuccess(response.data);

      return response.data;
    })
    .catch(error => {
      if (error.response)
        errors.updateErrors(error.response.data);
      else
        console.log(error);

      return {};
    });
  }

  const getJoinData = async (eventId) => {
    if (!cache.has(`join_data_${eventId}`)) {
      cache.setData(
        `join_data_${eventId}`,
        axios.get(
          `${API_URL}event/${eventId}/join-data`,
          {
            params: {
              token: token
            }
          }
        ).then(response => {
          return response.data;
        }).catch(error => {
          //errors.updateErrors(error.response.data);
          return {};
        })
      );
    }

    return cache.getData(`join_data_${eventId}`);
  }

  return {
    getActiveEvents,
    getEvent,
    join,
    getJoinData
  }
}