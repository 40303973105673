import axios from 'axios';

import { API_URL } from '../../../config/default';
import { useAuth } from '../auth';
import { useCache } from '../cache';
import { useError } from "../error";

export function EventCategory () {

  const errors = useError();
  const { token } = useAuth();
  const cache = useCache();

  const getEventCategories = async (eventId) => {
    if (!cache.has(`event_categories_${eventId}`)) {
      errors.clearErrors();

      cache.setData(
        `event_categories_${eventId}`,
        axios.get(API_URL + 'event-category', {
          params: {
            event_id: eventId
          }
        }).then(response => {
          return response.data;
        }).catch(error => {
          errors.updateErrors(error.response.data);
          return {};
        })
      );
    }

    return cache.getData(`event_categories_${eventId}`);
  }

  const getEventCategory = async (id) => {}

  const join = async (id, type, onSuccess ) => {
    errors.clearErrors();

    return axios.post(
      `${API_URL}event-category/${id}/join`,
      {
        token: token,
        join_type: type
      }
    ).then(response => {
      cache.removeByPrefix('join_data_');
      errors.updateErrors([{
        level: 'SUCCESS',
        message: 'Sikeres csatlakozás!'
      }]);

      if (typeof onSuccess !== "undefined") onSuccess(response.data);

      return response.data;
    })
    .catch(error => {
      if (error.response)
        errors.updateErrors(error.response.data);
      else
        console.log(error);

      return {};
    });
  }

  return {
    getEventCategories,
    getEventCategory,
    join
  }
}