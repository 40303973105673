import React from "react";
import Content from "./Content";
import Header from "./Header";

export default function Layout({children}) {
  return (
    <div id="app">
      <Header />
      <Content>
        {children}
      </Content>
    </div>
  );
}