import React, { useContext, createContext } from "react";

const CacheContext = createContext();

export function ProvideCache({ children }) {
  const Cache = useProvideCache();
  return <CacheContext.Provider value={Cache}>{children}</CacheContext.Provider>;
}

export const useCache = () => {
  return useContext(CacheContext);
};

function useProvideCache() {
  const data = new Map();
  const cacheShortExpireDuration = 10 * 60 * 1000;
  const cacheExpireDuration = 1 * 60 * 60 * 1000;
  const cacheLongExpireDuration = 2 * 60 * 60 * 1000;
  
  const getData = (key) => {
    if (!data.has(key) || data.get(key).dateExpire < new Date()) {
      data.delete(key);
      return null;
    }
    
    return data.get(key).data;
  }

  const setData = (key, value, expireDuration) => {
    let realValue = {
      data: value,
      dateExpire: new Date()
    };
    
    switch (expireDuration) {
      case 'short':
        realValue.dateExpire.setTime(realValue.dateExpire.getTime() + cacheShortExpireDuration);
        break;
      case 'long':
        realValue.dateExpire.setTime(realValue.dateExpire.getTime() + cacheLongExpireDuration);
        break;
      default:
        realValue.dateExpire.setTime(realValue.dateExpire.getTime() + cacheExpireDuration);
    }
    
    
    data.set(key, realValue);
  }

  const remove = (key) => {
    data.delete(key);
  }

  const removeByPrefix = (prefix) => {
    [...data.keys()].filter(key => {
      return key.startsWith(prefix);
    }).forEach(key => {
      data.delete(key);
    });
  }

  const clear = () => {
    [...data.keys()].forEach(key => {
      data.delete(key);
    });
  }

  const has = (key) => {
    return data.has(key) && data.get(key).dateExpire >= new Date();
  }

  // Return the Cache methods
  return {
    getData,
    setData,
    remove,
    removeByPrefix,
    clear,
    has
  };
}