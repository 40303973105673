import React from "react";
import { useAuth } from "../context/auth";

import Logo from '../../assets/images/magadert-sportolj-logo.png';
import SectionDivider from '../../assets/images/divider.svg';
import UserInfo from "../element/header/UserInfo";

export default function Header() {
  const auth = useAuth();

  return (
    <div id="header">
      <div className="d-flex align-items-center ps-3 pe-3">
        <a href="/"><img src={Logo} className="header-logo" alt="Magadért Sportolj!" title="Magadért Sportolj!" /></a>
        {
          auth.verify()
          ?
          <UserInfo userImage={auth.user?auth.user.image:"https://i.imgur.com/nGKxX6x.png"} userName={auth.user?auth.user.name:<><i className="fa-solid fa-spinner fa-spin-pulse"></i> Töltés...</>} userDisplayName={auth.user?auth.user.display_name:<><i className="fa-solid fa-spinner fa-spin-pulse"></i> Töltés...</>} />
          // <UserInfo userImage="https://i.imgur.com/nGKxX6x.png" userName="Weller Zoltán" userDisplayName="auto_message" />
          :
          // <button className="btn btn-primary ms-auto"><i className="fa-solid fa-user me-2"></i> Bejelentkezés</button>
          ""
        }
      </div>
      <div className="header-divider-container"><img src={SectionDivider} alt="section divider" /></div>
    </div>
  );
}