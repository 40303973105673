import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useAPI } from "../../context/api";

const EventList = React.lazy(() => import('../../page/EventList'));
const Event = React.lazy(() => import('../../page/Event'));

export default function Router () {
  const api = useAPI();
  const [ hasMultipleActive, setHasMultipleActive ] = useState(false);

  useEffect(() => {
    api.event.getActiveEvents().then(eventsData => {
      if (eventsData.length > 1) {
        setHasMultipleActive(true);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <Routes>
        <Route path="/*" element={hasMultipleActive?<EventList />:<Event />} />
        <Route path="/event/:eventId/*" element={<Event />} />
      </Routes>
  );
}